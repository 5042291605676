/* eslint-disable  @typescript-eslint/no-explicit-any */

export function isObjectEmpty(object: any): boolean {
  return (
    !exists(object) ||
    (object.length !== undefined && object.length === 0) ||
    Object.keys(object).length === 0
  );
}

export function exists(object: any): boolean {
  return object !== undefined && object !== null;
}

export function emptyFunction(): void {
  return undefined;
}

export function emptyPromiseFunction(): Promise<null> {
  return new Promise(() => null);
}

export function objectToFormData(
  formData: FormData,
  data: any,
  parentKey: string = ''
): void {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      objectToFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export function isZeroEntry(object: any): boolean {
  const values = Object.values(object);

  for (const entry of values) {
    if (typeof entry === 'number') {
      if (entry !== 0) {
        return false;
      }
    } else {
      return false;
    }
  }
  return true;
}

export function sortByProperty<T>(
  array: T[],
  prop: keyof T,
  order: 'asc' | 'desc' = 'asc'
): T[] {
  return array.sort((a, b) => {
    const propA = a[prop];
    const propB = b[prop];
    const sortOrder = order === 'desc' ? -1 : 1;
    if (propA < propB) {
      return sortOrder * -1;
    }
    if (propA > propB) {
      return sortOrder * 1;
    }
    return 0; // properties must be equal
  });
}

export function deepCopy<T>(instance: T): T {
  if (instance == null) {
    return instance;
  }

  // handle Dates
  if (instance instanceof Date) {
    return new Date(instance.getTime()) as any;
  }

  // handle Array types
  if (instance instanceof Array) {
    const cloneArr = [] as any[];
    (instance as any[]).forEach((value) => {
      cloneArr.push(value);
    });
    // for nested objects
    return cloneArr.map((value: any) => deepCopy<any>(value)) as any;
  }
  // handle objects
  if (instance instanceof Object) {
    const copyInstance = {...(instance as { [key: string]: any })} as {
      [key: string]: any;
    };
    for (const attr in instance) {
      if (Object.hasOwn(instance, attr)) {
        copyInstance[attr] = deepCopy<any>((instance as { [key: string]: any })[attr]);
      }
    }
    return copyInstance as T;
  }
  // handling primitive data types
  return instance;
}

export function areObjectsEqual<T extends object>(a: T, b: T): boolean {

  return Object.keys(a).length === Object.keys(b).length &&
    (Object.keys(a) as (keyof typeof a)[]).every((key) => {
      if (a[key] !== null && b[key] !== null && typeof a[key] === 'object') {
        return areObjectsEqual(a[key] as object, b[key] as object);
      }
      return a[key] === b[key];
    });
}

export function valueExistsInObject(term: string, object: any): boolean {
  if (object === null) {
    return false;
  }

  const searchQuery = term.toLowerCase();
  const values = Object.values(object);

  let result = false;

  for (const entry of values) {
    if (entry !== null) {
      switch (typeof entry) {
        case 'string':
          result = entry.toLowerCase().includes(searchQuery);
          break;
        case 'number':
          result = entry.toString().toLowerCase().includes(searchQuery);
          break;
        case 'boolean':
          break;

        default:
          result = valueExistsInObject(searchQuery, entry);
      }
    }

    if (result) {
      break; //found positive result so stop
    }
  }

  return result;
}

export function removeEmptyStrings(array: string[]): string[] {
  // Filter out empty strings, whitespace-only strings, and null/undefined values
  return array?.filter(str => str && str.trim().length > 0) || array;
}
